
/* injects from baggage-loader */

/* tslint:disable:TS2695 */
export const bs = {
    translation: {
        " | kakava.lt": " | ",
        "Aaaaaah! Tokio puslapio nėra :(": "Aaaaaah! ",
        "Adresas": "Adresa",
        "Akcija!": "Rasprodaja!",
        "Akcija": "Posebna ponuda",
        "Akcijos": "Posebne ponude",
        "Aktyvacijos klaida": "Greška pri aktivaciji",
        "Amžiaus cenzas": "Dob za pristanak",
        "Apie atlikėją": "O izvođaču",
        "Apie organizatorių": "O organizatoru događaja",
        "Apie renginį": "Informacije o događaju",
        "Apmokėjimas": "Plaćanje",
        "Apmokėtas": "Plaćeno",
        "Apmokėti galėsite dovanų kuponu, kortele arba internetine bankininkyste": "Moći ćete platiti poklon vaučerom, karticom ili internet bankarstvom",
        "Apmokėti": "Plati",
        "Ar krepšelis apmokėtas?": "Da li je kolica plaćena?",
        "Ar norite anuliuoti krepšelį?": "Želite da otkažete korpu?",
        "Asmeniniai duomenys": "Lični podaci",
        "Atraskite muzikos, teatro, kultūrinius ir sporto renginius, kurių bilietus įsigysite paprasčiau, greičiau ir patogiau per kakava.lt": "Otkrijte muziku, pozorište, kulturne i sportske događaje i kupujte karte lakše, brže i povoljnije na kakava.lt",
        "Atsijungti": "Odjavite se",
        "Atsisiųsti pažymėtus bilietus": "Preuzmite provjerene karte",
        "Atsisiųsti pažymėtus kuponus": "Preuzmite odabrane poklon kartice",
        "Pridėti draudimą visiems bilietams": "Dodajte osiguranje na sve karte",
        "Bilietų draudimas": "Osiguranje karata",
        "Gaukite kompensaciją už nepanaudotą bilietą su bilietų draudimu!": "Primite nadoknadu za neiskorištenu kartu uz osiguranje karata!",
        "Plačiau apie sąlygas sužinokite čia": "Više o uslovima i odredbama pročitajte ovdje",
        "Atsisiųsti visus bilietus": "Preuzmite sve karte",
        "Atsisiųsti visus kuponus": "Preuzmite sve poklon kartice",
        "Atsisiųsti": "Preuzmi",
        "Atšaukta": "Otkazano",
        "Atšauktas renginys": "Događaj je otkazan",
        "Atšaukti": "Otkaži",
        "Bilietai išsiųsti": "Ulaznice poslane",
        "Bilietai jau tavo!": "Karte su vaše!",
        "Bilietai nerasti": "Karte nisu pronađene",
        "Bilietai spausdinimui": "Ulaznice za štampu",
        "Bilietai sėkmingai išsiųsti draugui!": "Karte su uspješno poslate prijatelju!",
        "Bilietai į renginius internetu: koncertai, teatras, pramogos": "Online ulaznice za događaje: koncerte, pozorište, zabavu, sport",
        "Bilietai į renginius – paprasčiau nei paprastai! Greitas bilietų pirkimo procesas – su kakava.lt! Muzika, teatras, kultūros ir sporto renginiai.": "Ulaznice za događaje – jednostavnije nego inače! ",
        "Bilietai": "Ulaznice",
        "Bilietams ir sąskaitai atsisiųsti liko": "Preostalo vrijeme za preuzimanje karata i računa",
        "Bilietas": "Ticket",
        "Bilieto kaina": "Cijena ulaznice",
        "Bilietus išsiuntėme el. paštu": "E-mail sa vašim ulaznicama je poslan",
        "Bilietus į populiariausius muzikos, teatro, kultūros ir sporto renginius įsigykite greičiau ir paprasčiau su kakava.lt – pažangiu bilietų platintoju.": "Nabavite karte za najpopularnije muzičke, pozorišne, kulturne i sportske događaje brže i lakše uz kakava.lt – inovativni prodavač karata.",
        "Bilietų kainos": "Cijene",
        "Bilietų pardavimas pasibaigęs": "Prodaja ulaznica je završena",
        "Buto Nr.": "Apartman br",
        "Dalinkis su draugais": "Podijelite sa svojim prijateljima",
        "Data nuo iki": "Datum",
        "Data": "Datum",
        "Daugiau": "Više",
        "Dirbame kasdien 8-22 val.": "Radno vrijeme: svaki dan od 8:00 do 22:00 sata",
        "Dovanų kuponai": "Poklon kartice",
        "Duomenys atnaujinti": "Podaci ažurirani",
        "Duomenys sąskaitai faktūrai": "Detalji fakture",
        "Durys atidaromos": "Vrata se otvaraju prije",
        "Eilė": "Red",
        "El. laiškas su slaptažodžio priminimo nuoroda išsiųstas.": "E-mail sa linkom za podsjetnik lozinke je poslan.",
        "El. paštas sąskaitai": "E-mail za fakturu",
        "El. paštas": "Email",
        "Esi užsiregistravęs?": "Jeste li prijavljeni?",
        "Filtruoti renginius": "Filtrirajte događaje",
        "Filtruoti": "Filter",
        "Fizinis asmuo": "Pojedinac",
        "Galimos dovanų kuponų kainos": "Dostupne cijene poklon kartica",
        "Gimimo metai": "Godina rođenja",
        "Grąža": "Promjena",
        "Grįžti atgal": "Nazad",
        "Grįžti į pradinį langą": "Povratak na početnu stranicu",
        "Grįžti į renginį": "Povratak na događaj",
        "Ieškokite miesto": "Potražite grad",
        "Ieškoti renginių": "Tražite događaje",
        "Ieškoti": "Traži",
        "Iki": "To",
        "Informacija apie renginį": "Informacije o događaju",
        "<0>Informuojame, kad šioje svetainėje naudojami slapukai (angl. cookies). Tęsdami naršymą šioje svetainėje, jūs sutinkate su mūsų Privatumo politika.</0>Daugiau apie <2>Privatumo politiką</2>.": "<0>Ova web stranica koristi kolačiće. </0> Više o <2>Politika privatnosti</2>.",
        "Informuojame, kad šioje svetainėje naudojami slapukai (angl. cookies). Tęsdami naršymą šioje svetainėje, jūs sutinkate su mūsų Privatumo politika.": "Ova web stranica koristi kolačiće. ",
        "Internetinė bankininkystė": "Online bankarstvo",
        "Interneto puslapis": "Website",
        "Iš viso": "Ukupno",
        "Išparduota": "Rasprodano",
        "Išsaugoti": "Sačuvaj",
        "Išvalyti duomenis": "Očistite detalje",
        "Išvalyti": "Jasno",
        "Jau <1>{{eventDate}}</1>d. <3>{{locationName}}</3> išvysite „<5>{{eventTitle}}</5>“. Renginio bilietus įsigysite greičiau per kakava.lt": "Već je uključeno <1>{{eventDate}}</1>d. <3>{{locationName}}</3> vidjet ćeš „<5>{{eventTitle}}</5>“. ",
        "Jei dar nesi sumokėjęs, <1>spausk čia</1>": "Ako još niste uplatili, <1>kliknite ovdje</",
        "Juridinis asmuo": "Pravno lice",
        "Kaina nuo": "Cijena od",
        "Kaina": "Cijena",
        "Kakava LT © 2018": "Kakava LT © 2018",
        "Kasa": "Box office",
        "Keisti sektorių": "Promijenite sektor",
        "Keisti vietas": "Promijenite sjedišta",
        "Keisti vietą ir laiką": "Promijenite mjesto i vrijeme",
        "Skirtingos lokacijos": "Više lokacija",
        "Kita": "Ostalo",
        "Kiti sektoriai": "Ostali sektori",
        "Kiti siūlomi renginiai": "Ostali dostupni događaji",
        "Koncertų, teatro, kultūros ir sporto renginių naujienos bei jų bilietai – vienoje vietoje. Susipažinkite su kakava.lt": "Ulaznice za nove koncerte, pozorišne, kulturne i sportske događaje na jednom mjestu. ",
        "Kontaktai": "Kontakti",
        "Kortelės kodas": "Kod kartice",
        "Krepšelis laukia apmokėjimo.": "Korpa čeka plaćanje.",
        "<0>Krepšelis tuščias</0><1>ir išalkęs bilietų. Papildom?</1>": "<0>Korpa je prazna</0><1>i gladan karata. </1>",
        "Krepšelis": "Cart",
        "Kvitas nerastas": "Račun nije pronađen",
        "Kuponai": "Poklon kartice",
        "kuponai": "poklon kartice",
        "kuponas": "poklon kartica",
        "Kuponas": "Poklon kartica",
        "kuponų": "Poklon kartice",
        "Kuponai jau tavo!": "Poklon kartice su vaše!",
        "Kuponus išsiuntėme el. paštu": "Poslali smo Vaše poklon kartice na e-mail",
        "Lange bilietai atsiras iškart, kai sugeneruosime.": "Ulaznice će se pojaviti u prozoru odmah kada ih generišemo.",
        "Liko laiko": "Preostalo vrijeme",
        "Lvovo g. 89-79, Vilnius": "Lvovo str. ",
        "Kalba": "Jezik",
        "Mano bilietai": "Moje karte",
        "Mano duomenys": "Moji detalji",
        "Mano krepšelis": "Moja kolica",
        "Mano mėgstami renginiai": "Moji omiljeni događaji",
        "Mažiau": "Manje",
        "Miestas": "Grad",
        "Mobilieji mokėjimai": "Mobilna plaćanja",
        "Mokėjimo kortelė": "Platna kartica",
        "Mokėjimui atlikti turi 20 minučių": "Imate 20 minuta za plaćanje",
        "Mėgstami renginiai": "Omiljeni događaji",
        "Namo Nr.": "Kuća br",
        "Naujas slaptažodis": "Nova lozinka",
        "Naujiena": "Novo",
        "Ačiū, kad prenumeruoji kakava.lt naujienlaiškį. Prisijunk prie savo pašto dėžutės ir patvirtink kad sutinki gauti kakava.lt  naujienas.": "Hvala vam što ste se pretplatili na kakava.lt newsletter. ",
        "Tavo patvirtinimą gavome, nuo šiol visas naujienas sužinosi pirmas!": "Dobili smo vašu potvrdu, od sada ćete prvi saznati sve novosti!",
        "Naujienlaiškio aktyvacija": "Aktivacija biltena",
        "Naujienlaiškio aktyvacija nepavyko.": "Aktivacija biltena nije uspjela.",
        "Naujienlaiškis": "Newsletter",
        "Naujienos": "Vijesti",
        "Ne": "br",
        "Neapmokėtas": "Neplaćeno",
        "Nerasta": "Nije pronađeno",
        "Nesutampa su {fieldLabel}": "Ne podudara se s {fieldLabel}",
        "Neturi prisijungimo?": "Novi ste na kakava.lt? ",
        "Nori rinktis daugiau renginių?": "Želite li odabrati više događaja?",
        "Norint įsigyti daugiau nei 10 bilietų, prašome kreiptis": "Za kupovinu više od 10 karata, kontaktirajte",
        "Noriu gauti kakava.lt  ir / arba renginio organizatoriaus naujienas": "Želim da dobijem kakava.lt i/ili bilten promotera",
        "Nesutinku, kad mano kontaktiniai duomenys būtų naudojami panašių prekių ar paslaugų rinkodarai": "Ne slažem se da se moji kontakt podaci dijele za oglašavanje sličnih proizvoda ili usluga",
        "Pritaikyti nuolaidą": "Primijenite popust",
        "Keisti nuolaidą": "Promjena popusta",
        "Norėdamas pasirinkti vietą, spausk ant jos salės plane": "Da biste odabrali mjesto, kliknite na njega u planu mjesta",
        "Nuo brangiausio iki pigiausio": "Cijena: najviša prva",
        "Nuo greičiausiai prasidedančio": "Vrijeme: počinje najranije",
        "Nuo pigiausio iki brangiausio": "Cijena: prvo najniža",
        "Nuo vėliausiai prasidedančio": "Vrijeme: počinje najkasnije",
        "Nuo": "Od",
        "Nuolaida pritaikyta": "Primijenjen popust",
        "Nuolaidos kodas": "Kod za popust",
        "Nuolaidos": "Popusti",
        "Nurodyk, kur siųsti bilietus": "Javite nam gdje da pošaljemo karte",
        "Nurodyta priimama suma yra mažesnė nei krepšelio suma.": "Navedeni prihvaćeni iznos je manji od iznosa kolica.",
        "Nurodytas neteisingas el.pašto adresas.": "Nevažeća adresa e-pošte.",
        "Nurodyti el.pašto adresai turi sutapti.": "Navedene adrese e-pošte moraju se podudarati.",
        "Nurodytos slaptažodžių reikšmės turi sutapti.": "Navedene vrijednosti lozinke moraju se podudarati.",
        "O ne! Apmokėjimas nepavyko. Pasirink kitą mokėjimo būdą ir pamėgink dar kartą.": "Oh ne! ",
        "O ne! Kažkas nutiko. Klaida yra dalis progreso, atkreipsime į ją dėmesį, o tu pamėgink dar kartą.": "Oh ne! ",
        "O ne! Neradome nieko, kas atitiktų filtrą. Pamėgink nurodyti kitą reikšmę.": "Oh ne! ",
        "O ne! Nieko neradome. Paieškai reikia mažiausiai trijų simbolių, tad pamėgink dar kartą.": "Oh ne! ",
        "O ne! Nieko neradome. Pamėgink patikrinti rašybą arba ieškoti pagal atlikėjo, renginio, organizatoriaus ar arenos pavadinimą.": "Oh ne! ",
        "OK, mokėjimą atlikote": "U redu, izvršili ste uplatu",
        "Oops. Matom, kad mokėjimas atmestas. Pirmas blynas kartais prisvyla – bandom apmokėti kitu būdu?": "Ups. ",
        "Operacija baigta.": "Operacija završena.",
        "Ouch. Atsitrenkėm į klaidą iš banko, bičiuli. Bandyk kitą mokėjimo variantą.": "Jao. ",
        "Ouch. Atsitrenkėm į klaidą, bičiuli. Pirmas blynas kartais prisvyla – bandom apmokėti kitu būdu?": "Jao. ",
        "Ouch. Atsitrenkėm į neaiškią klaidą iš banko, bičiuli. Bandyk kitą mokėjimo variantą.": "Jao. ",
        "Pasirink kuponus": "Odaberite poklon kartice",
        "Pasirinkite dovanų kuponą": "Odaberite poklon karticu",
        "POS Klaida spausdinant bilietus": "Greška na POS-u prilikom štampanja karata",
        "POS Klaida spausdinant kvitą": "POS greška prilikom štampanja računa",
        "POS Klaida": "POS greška",
        "POS Spausdinimas": "POS štampanje",
        "PVM kodas": "PDV broj",
        "PVM mokėtojo kodas turi būti sudarytas iš 11-14 simbolių.": "PDV broj mora imati 11-14 znakova.",
        "Paieška": "Traži",
        "Paieškos rezultatai pagal užklausą": "Rezultati pretraživanja po upitu",
        "Pakartok el. paštą": "Ponovo unesite svoju e-poštu",
        "Pakartok naują slaptažodį": "Ponovo unesite novu lozinku",
        "Pakeisti slaptažodį": "Promijenite lozinku",
        "Pamiršai slaptažodį? Kam nepasitaiko, bičiuli. Įvesk savo el. pašto adresą.": "Zaboravili ste lozinku? ",
        "Pamiršau slaptažodį": "Zaboravili ste lozinku",
        "Pardavimų ataskaita": "Izvještaj o prodaji",
        "Pasibaigęs": "Događaj je istekao",
        "Pasirink apmokėjimo būdą": "Odaberite način plaćanja",
        "Pasirink ataskaitos laikotarpį": "Odaberite period izvještaja",
        "Pasirink bilietus": "Odaberite karte",
        "Pasirink bilietą": "Odaberite kartu",
        "Pasirink miestą": "Odaberite grad",
        "Pasirink nuolaidą": "Odaberite popust",
        "Pasirink sektorių": "Odaberite sektor",
        "Pasirink tau priklausančią nuolaidą": "Odaberite svoj popust",
        "Pasirink vietą": "Odaberite sjedište",
        "Pasirink": "Odaberite",
        "Pavardė": "Prezime",
        "Pašalinti": "Ukloni",
        "Pašto kodas": "Poštanski broj",
        "Per ilgas (iki {count, number} {count, plural, one {simbolio} other {simbolių}})": "Predugo (do {count, number} {count, plural, jedan {symbol} drugi {symbols}})",
        "Per trumpas (bent {count, number} {count, plural, one {simbolis} other {simboliai}})": "Prekratko (najmanje {count, number} {count, plural, jedan {symbol} drugi {symbols}})",
        "Perkamiausi renginiai": "Najprodavaniji",
        "Perkelta": "Preseljeno",
        "Perkeltas renginys": "Premješten događaj",
        "Persiųsk bilietą draugui": "Proslijedite kartu svom prijatelju",
        "Persiųsk kuponą draugui": "Proslijedite poklon karticu prijatelju",
        "Pertraukos": "Pauze",
        "Peržiūrėk savo nurodytą el. pašto adresą dar kartą.": "Provjerite svoju email adresu ponovo.",
        "Peržiūrėti visus": "Pogledaj sve",
        "Pirk su MoQ ir 2 Eur susigrąžink atgal!": "Pirk su MoQ ir 2 Eur susigrąžink atgal!",
        "Pirkti bilietus VIP sąlygomis": "Kupite karte kao VIP",
        "Pirkti bilietus įprastomis sąlygomis": "Kupujte karte kao jednostavan korisnik",
        "Pirkti bilietus": "Kupite karte",
        "Pirkti kuponus": "Kupite poklon kartice",
        "Pirkėjo el. paštas (neprivalomas)": "E-mail kupca (opcionalno)",
        "Pirkėjo el. paštas": "E-mail kupca",
        "Pirkėjo mob. telefono nr.": "Mob kupaca. ",
        "Pirkėjo pavardė": "Prezime kupca",
        "Pirkėjo vardas": "Ime kupca",
        "PIRKTI VIP KLUBO/LOŽĖS BILIETUS": "KUPITE KARTE ZA VIP KLUB/LOŽU",
        "Platintojas veikia kaip atsiskleidęs tarpininkas. Atsakomybę dėl įvykusio renginio ir jo kokybės prisiima Renginio Organizatorius. Dėl pinigų grąžinimo renginio nukėlimo ar atšaukimo atveju sprendimą priima organizatorius.": "Prodavač ulaznica (kakava.lt) djeluje kao otkriveni agent. ",
        "Plačiau": "Više",
        "Populiarus": "Popularno",
        "Pradėkime nuo pradžių": "Počnimo od početka",
        "Pranešk draugams!": "Reci svojim prijateljima!",
        "Prašome nurodyti Gimimo metus.": "Navedite godinu rođenja.",
        "Prašome nurodyti miestą.": "Navedite grad.",
        "Prašome nurodyti pinigų sumą": "Navedite iznos novca",
        "Prašome įvesti telefono numerį.": "Navedite broj telefona.",
        "Prašome pasirinkti banką.": "Odaberite banku.",
        "Prašome pasirinkti mokėjimo būdą": "Odaberite način plaćanja",
        "Prašome pasirinkti mokėjimo būdą.": "Odaberite način plaćanja.",
        "Prašome pataisyti nurodytą reikšmę. Sumą po kablelio privalo sudaryti vienas arba du skaitmenys.": "Ispravite navedenu vrijednost. ",
        "Prašome pataisyti nurodytą reikšmę. Sumą turi sudaryti skaičiai.": "Ispravite navedenu vrijednost. ",
        "Prašome pataisyti nurodytą reikšmę. Varde negali būti skaičių.": "Ispravite navedenu vrijednost. ",
        "Prašome įvesti adresą.": "Unesite adresu.",
        "Prašome įvesti el. pašto adresą.": "Unesite email.",
        "Prašome įvesti miestą.": "Uđite u grad.",
        "Prašome įvesti pavadinimą.": "Unesite ime.",
        "Prašome įvesti pavardę.": "Unesite prezime.",
        "Prašome įvesti slaptažodį.": "Unesite lozinku.",
        "Prašome įvesti vardą.": "Unesite ime.",
        "Prašome įvesti įmonės adresą.": "Unesite svoju poslovnu adresu.",
        "Prašome įvesti įmonės kodą.": "Unesite šifru preduzeća.",
        "Prašome įvesti žinutę.": "Unesite svoju poruku.",
        "Priimta suma grynais": "Prihvaćeni iznos u gotovini",
        "Prisijungimas su Facebook nepavyko": "Prijava putem Facebooka nije uspjela",
        "Prisijungimas": "Prijavite se",
        "Prisijungimo klaida": "Greška pri prijavi",
        "Prisijungti": "Prijavite se",
        "Prisijunk prie mūsų": "Pridružite nam se",
        "Prisijunk įvesdamas savo prisijungimo duomenis – galėsi išsisaugoti patikusius renginius bei įsigyti bilietus.": "Prijavite se unosom vaših podataka za prijavu – možete sačuvati svoje omiljene događaje i kupiti karte.",
        "Prisijunk": "Prijavite se",
        "Prisiminti mane": "Zapamti me",
        "Pritaikyta nuolaidų": "Primjenjuju se popusti",
        "Privalomas laukas": "Obavezno polje",
        "Redaguoti": "Uredi",
        "Registracija sėkminga": "Uspješno ste se prijavili",
        "Registracija sėkminga. Patikrink paštą ir aktyvuok savo vartotoją.": "Uspješno ste se prijavili. ",
        "Registracijos klaida": "Greška u registraciji",
        "Registruokis": "Registrirajte se",
        "Registruotis": "Registrirajte se",
        "Reikės sąskaitos faktūros": "Treba mi faktura",
        "Renginiai": "Događaji",
        "renginiai": "događaji",
        "Renginio akimirkos": "Trenuci događaja",
        "Renginio pradžia": "Počni",
        "Renginio tipas": "Vrsta događaja",
        "Renginio trukmė": "Trajanje",
        "renginių": "događaji",
        "Renginių bilietai – pigiau! Geriausius muzikos, teatro, sporto ir kultūros renginių pasiūlymus ir kitas akcijas rasite čia.": "Ulaznice za događaje – jeftinije! ",
        "Renginys": "Događaj",
        "renginys": "Događaj",
        "Rezervacijos dokumentas": "Dokument o rezervaciji",
        "Rinkis bilietus iš naujo": "Ponovo izaberite karte",
        "Rinktis kuponą": "Odaberite poklon karticu",
        "Rinktis vietą ir laiką": "Odaberite mjesto i vrijeme",
        "Rūšiuoti pagal": "Sortiraj po",
        "Saugoti": "Sačuvaj",
        "Saugumo sumetimais bilietų ir sąskaitų faktūrų neberodome": "Iz sigurnosnih razloga više ne prikazujemo karte i račune",
        "Sektorius": "Sektor",
        "Senas slaptažodis": "Stara lozinka",
        "Sesija baigėsi – prisijunk iš naujo.": "Sesija je gotova – prijavite se ponovo.",
        "Sesijos laikas baigėsi": "Isteklo je vrijeme sesije",
        "Siųsti atstatymo nuorodą": "Pošaljite link za resetovanje",
        "Siųsti bilietus el.paštu": "Ulaznice pošaljite na email",
        "Siųsti kuponus el.paštu": "Pošaljite kupon na email",
        "Siųsti pažymėtus bilietus draugui": "Podijelite provjerene karte sa svojim prijateljem",
        "Siųsti pažymėtus kuponus draugui": "Pošaljite označene poklon kartice prijatelju",
        "Siųsti": "Pošalji",
        "Skirtingos vietos": "Različite lokacije",
        "Slaptažodis pakeistas sėkmingai.": "Lozinka je uspješno promijenjena.",
        "Slaptažodis pakeistas": "Lozinka promijenjena",
        "Slaptažodis": "Lozinka",
        "Slaptažodį turi sudaryti mažiausiai 6 simboliai.": "Lozinka mora imati najmanje 6 znakova.",
        "Slaptažodžio atkūrimas": "Oporavak lozinke",
        "Slaptažodžio keitimas nepavyko": "Promjena lozinke nije uspjela",
        "Slaptažodžio keitimas": "Promjena lozinke",
        "Slaptažodžio priminimas": "Podsjetnik lozinke",
        "Spausdinti bilietus": "Štampajte karte",
        "Spausdinti kvitą": "Odštampajte račun",
        "Spausdinti kuponus": "Štampajte poklon kartice",
        "Suma": "Ukupno",
        "Supakuok savo bilietus": "Poklonite kartu",
        "Supakuoti": "Zamotajte kartu",
        "Susikurk naują slaptažodį ir jį pakartok.": "Kreirajte novu lozinku i ponovite je.",
        "Susipažinau su pirkimo <1>taisyklėmis</1>": "Pročitao sam kupovinu <1>pravila</1>",
        "Susipažinau su <1>taisyklėmis</1>": "Pročitao sam <1>pravila</1>",
        "Susisiek": "Kontaktirajte nas",
        "Sutinku": "Slažem se",
        "Svarbi informacija": "Važne informacije",
        "Sąskaita faktūra": "Račun",
        "Taip": "Da",
        "Tam, kad taptum kakava.lt vartotoju, mes turime turėti tavo el.pašto adresą. Neturėdami tavo el. pašto, negalėsime tau išsiųsti bilietų, pateikti specialių pasiūlymų ar kitos informacijos. Todėl spausk dar kartą mygtuką, sutik mums duoti savo duomenis, ir prisijunk prie mūsų!": "Da bismo postali korisnik kakava.lt potrebno je da imamo Vašu email adresu. ",
        "Tavo bilietai": "Vaše karte",
        "Tavo duomenys atnaujinti sėkmingai.": "Vaši podaci su uspješno ažurirani.",
        "Tavo el. paštas": "Vaš email",
        "Tavo kakava.lt nupirkti bilietai į renginį tuoj kažką labai pradžiugins. Įrašyk sveikinimo tekstą ir nusiųsk bilietą draugui ar artimajam!": "Obradujte nekoga ulaznicama za događaj koje ste kupili na kakava.lt. ",
        "Tavo kakava.lt nupirkti kuponai tuoj kažką labai pradžiugins. Įrašyk sveikinimo tekstą ir nusiųsk kuponą draugui ar artimajam!": "Obradujte nekoga poklon kuponom koji ste kupili na kakava.lt. ",
        "Tavo krepšelis": "Vaša kolica",
        "Tavo kuponai": "Vaše poklon kartice",
        "Tavo žinutė draugui": "Vaša poruka prijatelju",
        "Tačiau primename, kad savo bilietus ir sąskaitą faktūrą visada rasi el. pašte.": "Zapamtite da svoje karte i fakturu uvijek možete pronaći u svojoj e-pošti.",
        "Trumpam užsnūdai? Sesija baigėsi – prisijunk iš naujo.": "Odspavate? ",
        "Trūksta tik varnelės, kad galėtum tęsti!": "Nedostaje kvačica za nastavak!",
        "Turi būti pažymėta": "Mora se provjeriti",
        "Tęsti apmokėjimą": "Nastavite sa plaćanjem",
        "Tęsti": "Nastavi",
        "Uh-oh. Kortelė NEDRAUGAUJA su internetiniais pirkimais. Savo banke pakeisk kortelės nustatymus, kad leistų atsiskaityti internetu.": "Uh-oh. ",
        "Užimtos vietos": "Zauzeta mjesta",
        "Užsiregistruok": "Prijavite se",
        "Užsiregistruok: prisiminsime tave ir nereikės kaskart vesti duomenų iš naujo.": "Uštedite svoje vrijeme - prijavite se! ",
        "Užsisakyti": "Pretplatite se",
        "VIP ložės ir klubai": "VIP saloni i klubovi",
        "Vaikai įleidžiami nemokamai": "Djeca se primaju besplatno",
        "Valio! Slaptažodis sėkmingai pakeistas.": "Ura! ",
        "Vardas": "Ime",
        "Vartotojas aktyvuotas. Prašome prisijungti.": "Korisnik je aktiviran. ",
        "Vartotojas nėra aktyvuotas. Registracijos metu tau išsiuntėme el.laišką, prašome paspausti jame esančią nuorodą. Tada galėsi prisijungti.": "Korisnik nije aktiviran. ",
        "Vartotojo aktyvacija": "Aktivacija korisnika",
        "Vartotojo aktyvavimas nepavyko.": "Aktivacija korisnika nije uspjela.",
        "Vartotojo vardas arba slaptažodis yra neteisingi.": "Korisničko ime ili lozinka su netačni.",
        "Vieta": "Mjesto",
        "Visi sektoriai": "Svi sektori",
        "Visi": "Sve",
        "<0>Viskas, ko reikia – sekundė kantrybės! Lange bilietai atsiras iškart, kai sugeneruosime.</0><1>Taip pat bilietus atsiųsim el. paštu bei visada rasi savo paskyroje.</1>": "Sve što vam treba je sekunda strpljenja! <1></1>Vaše karte ćemo također poslati e-poštom i uvijek ćete ih pronaći na svom računu.",
        "<0>Viskas, ko reikia – sekundė kantrybės! Lange bilietai atsiras iškart, kai sugeneruosime.</0><1>Taip pat bilietus atsiųsime el. paštu.</1>": "<0>Sve što vam treba je sekunda strpljenja! </0><1>Vaše karte ćemo poslati i putem e-pošte.</1>",
        "<0>Viskas, ko reikia – sekundė kantrybės! Lange kuponai atsiras iškart, kai sugeneruosime.</0><1>Taip pat kuponus atsiųsim el. paštu bei visada rasi savo paskyroje.</1>": "<0>Sve što vam treba je sekunda strpljenja! </0><1>Poklon kartice ćemo poslati i e-poštom, a uvijek ih možete pronaći na svom računu.</1>",
        "<0>Viskas, ko reikia – sekundė kantrybės! Lange kuponai atsiras iškart, kai sugeneruosime.</0><1>Taip pat kuponus atsiųsime el. paštu.</1>": "<0>Sve što vam treba je sekunda strpljenja! </0><1> Poklon kartice ćemo poslati i e-mailom.</1>",
        "arba": "ili",
        "bilietai": "karte",
        "bilietas": "kartu",
        "bilietų": "karte",
        "iki": "to",
        "iš": "of",
        "kakava-lt.pdf": "kakva-lt.pdf",
        "kakava-lt_ataskaita_": "kakva-lt_report_",
        "kakava-lt_dienos-ataskaita.pdf": "kakava-lt_report-of-the-day.pdf",
        "kakava-lt_rezervacija.pdf": "kakva-lt_reservation.pdf",
        "kakava.lt | Kultūrinio gyvenimo vitaminai": "kakava.lt Vitamini kulturnog života",
        "metai": "godine",
        "metų amžiaus": "godine",
        "metų": "godine",
        "min.": "min.",
        "nauju slaptažodžiu": "sa novom lozinkom",
        "ne": "br",
        "neprivalomas": "opciono",
        "nuo brangiausio iki pigiausio": "cijena: najviša prva",
        "nuo greičiausiai prasidedančio": "vrijeme: počinje najranije",
        "nuo pigiausio iki brangiausio": "cijena: najniža prva",
        "nuo vėliausiai prasidedančio": "vrijeme: počinje najkasnije",
        "nėra": "nijedan",
        "Čia patogiai pateiksime tavo išsaugotus renginius. Radęs patinkantį renginį, spustelk širdutę jo išsaugojimui.": "Ovdje ćete pronaći svoje omiljene događaje. ",
        "Čia patogiai rasi savo jau įsigytus bilietus. Kaskart nusipirkus bilietus į patinkantį renginį, bilietai bus čia.": "Ovdje ćete pronaći sve karte koje ste kupili.",
        "Įdėti į krepšelį": "Dodaj u korpu",
        "Įmonės kodas": "Broj kompanije",
        "Įmonės pavadinimas": "Naziv kompanije",
        "Įpakavimo kaina": "Cijena pakovanja",
        "Įvesk el. pašto adresą": "Unesite svoju e-poštu",
        "Įveskite nuolaidos kodą": "Unesite kod za popust",
        "Įvykęs renginys": "Događaj je završen",
        "Jūs nematote laisvų/ užimtų vietų. Renginiui vietos parenkamos automatiškai. Jei parinktos vietos netinka, spauskite „Keisti vietas“": "Sjedala se biraju automatski za ovaj događaj",
        "Šis laukas yra privalomas.": "Ovo polje je obavezno.",
        "„<1>{{eventTitle}}</1>“ jau greitai jūsų mieste! Bilietus į artimiasią renginį įsigykite greičiau ir paprasčiau per kakava.lt.": "„<1>{{eventTitle}}</1>“Uskoro u vašem gradu! ",
        "„{{eventTitle}}“ jau greitai jūsų mieste! „{{eventTitle}}“ bilietai į renginį greičiau ir paprasčiau per kakava.lt.": "„{{eventTitle}}“Uskoro u vašem gradu! {{eventTitle}}“ Ulaznice za događaj brže i lakše uz kakava.lt.",
        "Jūsų naršyklė blokuoja Google": "Vaš pretraživač blokira Google",
        "Ginčai dėl sutarties netinkamo vykdymo ar nevykdymo ne teisme nagrinėjami Lietuvos Respublikos vartotojų teisių apsaugos įstatymo nustatyta tvarka Valstybinėje vartotojų teisių apsaugos tarnyboje, adresu Vilniaus g. 25, 01402 Vilnius, el. p. tarnyba@vvtat.lt, tel. (8 5) 262 67 51, faks. (8 5) 279 1466, interneto svetainė www.vvtat.lt. Elektroniniu būdu prašymą galite pateikti per EGS platformą http://ec.europa.eu/odr/.": "Sporovi u vezi sa neispravnim ili neispunjenjem ugovora van suda razmatraju se u skladu sa postupkom utvrđenim Zakonom o zaštiti prava potrošača Republike Litvanije u Državnoj službi za zaštitu prava potrošača, Vilniaus st. ",
        "Telefono nr.": "br.",
        "Apmokėta dovanų kuponu": "Plaćeno poklon karticom",
        "Dovanų kuponas": "Poklon kartica",
        "Įveskite dovanų kupono numerį": "Unesite broj poklon kartice",
        "Dovanų kupono numeris susideda tik iš skaičių": "Broj poklon kartice sastoji se samo od brojeva",
        "Toliau": "Nastavi",
        "Dovanų kupono Nr.:": "Broj poklon kartice:",
        "Suma:": "iznos:",
        "Ačiū, Dovanų kuponas panaudotas": "Hvala, poklon kartica je iskorištena",
        "Dovanų kuponais sumokėta:": "Poklon kartica je korištena za plaćanje:",
        "Liko mokėti:": "Ostaje za plaćanje:",
        "Dovanų kupono Nr.": "Broj poklon kartice",
        "Pridėti dovanų kuponą": "Dodajte poklon karticu",
        "Prisijunk su Facebook": "Prijavite se putem Facebooka",
        "Prisijunk su Google": "Prijavite se sa Google-om",
        "Susipažinau su pirkimo taisyklėmis": "Pročitao sam pravila kupovine",
        "Susipažinau su taisyklėmis": "Pročitao sam pravila",
        "Susipažinau su pirkimo": "Pročitao sam pravila",
        "taisyklėmis": "kupovine",
        "Su MoQ atgauk 1%* (išimtys <1>taisyklėse</1>)": "Uz MOQ vratite 1% * (izuzeci <1>u pravilima</1>)",
        "Įveskite Dovanų kupono kodą": "Unesite kod za poklon kupon",
        "Dovanų kupono numeris susideda iš 14 skaitmenų": "Šifra poklon kupona se sastoji od 14 cifara",
        "Dovanų kupono kodas:": "Broj poklon kupona:",
        "Dovanų kupono kodas": "Broj poklon kupona",
        "Bilietų grąžinimas": "Povraćaj karata",
        "Laukiame apmokėjimo": "Čeka se plaćanje",
        "Laukiame krepšelio apmokėjimo. Prašome neuždaryti lango.": "Čeka se plaćanje vaše korpe. ",
        "Įvesk išankstinio pardavimo kodą": "Unesite kod za pretprodaju",
        "Išankstinio pardavimo kodas": "Kod u pretprodaji",
        "Išankstinio pardavimo kodas neteisingas.": "Predprodajni kod je pogrešan",
        "Kodas": "Kod",
        "Patvirtinti": "Potvrdi",
        "Grąžinti": "Povrat novca",
        "Ar daryti grąžinimą?": "Vršite povrat novca?",
        "Ar grąžinimas atliktas?": "Da li je povrat izvršen?",
        "Atsidarykite Grąžinimo dokumentą (parsiųstas .pdf failas) ir atlikite pinigų grąžinimą klientui.": "Otvorite dokument za povrat novca (preuzetu .pdf datoteku) i izvršite povrat novca kupcu.",
        "Bilietai grąžinti": "Karte vraćene",
        "Ar tikslinti grąžinamų bilietų informaciją?": "Trebate li ažurirati informacije o ulaznicama?",
        "Įveskite grąžinamo bilieto numerį": "Unesite broj svoje karte za povrat novca",
        "Bilieto numeris susideda tik iš skaičių": "Broj karte se sastoji samo od brojeva",
        "Bilieto numeris": "Broj karte",
        "Pridėti bilietą": "Dodajte kartu",
        "Bilieto Nr.:": "Broj ulaznice:",
        "Grąžinamų bilietų informacija": "Informacije o povratu novca",
        "Renginiai, kurių bilietus galima grąžinti": "Događaji za koje se ulaznice mogu vratiti",
        "Šiuo metu nėra renginių, kurių bilietus galima grąžinti": "Trenutno nema događaja za koje se ulaznice mogu vratiti",
        "Grynieji pinigai / Mokėjimo kortelė": "Gotovina / Platna kartica",
        "Renginiai pagal kategorijas": "Događaji po kategorijama",
        "Apie festivalį": "O festivalu",
        "„{{groupTitle}}“ jau greitai jūsų mieste! Bilietus į artimiausią renginį įsigykite greičiau ir paprasčiau per kakava.lt.": "„{{groupTitle}}“uskoro stiže u vaš grad! ",
        "Festivaliai": "Festivali",
        "Kaina ": "Cijena ",
        "Nemokamas": "Besplatno",
        "Gauti bilietus": "Nabavite karte",
        "Asmeniniai pasiūlymai": "Lični popusti",
        "Pagalba kasoms": "Podrška na blagajni",
        "Pristatymo paslauga": "Fizička isporuka",
        "Gatvė": "Ulica",
        "Komentaras": "Komentar",
        "Papildomos paslaugos": "Dodatne usluge",
        "Klubo/ložės duomenys": "Podaci o klubu/domaći",
        "Kodas/Kortelės nr.": "Šifra/kartica br.",
        "Grįžti": "Zatvori",
        "Klubo/ložės pagal nurodytus duomenis rasti nepavyko.": "Klub/loža se ne može pronaći.",
        "Miestą turi sudaryti iki 100 simbolių.": "Grad mora imati do 100 znakova.",
        "Įmonės adresą turi sudaryti iki 100 simbolių.": "Adresa kompanije mora imati najviše 100 znakova.",
        "Įmonės kodą turi sudaryti iki 20 simbolių.": "Broj kompanije mora imati najviše 20 znakova.",
        "Sumokėta": "Plaćeno",
        "Bilietų kiekio keisti negalite": "Nemoguće je promijeniti broj karata",
        "Kakava LT, Žalgirio 135, Vilnius, Lietuva": "Kakava LT, Žalgirio 135, Vilnius, Litvanija",
        "Pridėti į Apple Wallet": "Dodajte u Apple Wallet",
        "Pridėti bilietus į Apple Wallet": "Dodajte karte u Apple Wallet",
        "Šią svetainę saugo reCAPTCHA ir Google. <1>Privatumo politika</1> bei <3>Paslaugų teikimo sąlygos</3>.": "Ova stranica je zaštićena reCAPTCHA i Google-om. <1>Politika privatnosti</1> i <3>Uslovi korištenja usluge</3>.",
        "Rezervacijų apmokėjimas": "Plaćanje rezervacije",
        "Įveskite rezervacijos numerį": "Unesite broj rezervacije",
        "Rezervacijos numeris susideda tik iš skaičių": "Broj rezervacije se sastoji samo od brojeva",
        "Rezervacijos numeris": "Broj rezervacije",
        "Tik Jūsų sutikimu mes naudojame slapukus, su tikslu pagerinti Jūsų naršymo kokybę bei tobulinti mūsų paslaugų turinį bei naršymo saugumą. Be to, būtinieji slapukai įgalina pagrindines mūsų svetainės funkcijas; ji negalėtų tinkamai veikti be šių slapukų, todėl šiems slapukams Jūsų sutikimas nėra būtinas; juos galima išjungti tik pakeitus naršyklės nuostatas.": "Kolačiće koristimo samo uz vaš pristanak, s ciljem poboljšanja kvalitete vašeg pregledavanja, a samim tim i sadržaja naših usluga i vaše sigurnosti pretraživanja. ",
        "Tik Jūsų sutikimu mes naudojame slapukus, su tikslu pagerinti Jūsų naršymo kokybę bei tobulinti mūsų paslaugų turinį bei naršymo saugumą. Detalesnė informacija mūsų <1>privatumo politikoje</1>. Be to, būtinieji slapukai įgalina pagrindines mūsų svetainės funkcijas; ji negalėtų tinkamai veikti be šių slapukų, todėl šiems slapukams Jūsų sutikimas nėra būtinas; juos galima išjungti tik pakeitus naršyklės nuostatas.": "Kolačiće koristimo samo uz vaš pristanak, s ciljem poboljšanja kvalitete vašeg pregledavanja, a samim tim i sadržaja naših usluga i vaše sigurnosti pretraživanja.  <1>politika privatnosti</1>. ",
        "Koreguoti pasirinkimą": "Podesite izbor",
        "Sutinku su visais": "Dozvoli sve kolačiće",
        "Ši svetainė naudoja slapukus": "Kolačići u upotrebi",
        "Sutinku su pasirinktais": "Dozvoli odabrane kolačiće",
        "Detaliau": "Više",
        "Pavadinimas": "Ime",
        "Aprašymas": "Opis",
        "Valdytojas": "Menadžer",
        "Trukmė": "Trajanje",
        "Palaukime": "Molimo pričekajte",
        "kol gausime atsakymą iš banko": "dok ne dobijemo potvrdu od banke",
        "Pridėtį į kalendorių": "Sačuvajte datum",
        "Uždaryti": "Zatvori",
        "Kainos": "Cijene",
        "Vietos tipai": "Vrste sedišta",
        "Vietų žymės": "Tip sjedišta",
        "Vietos": "Seats",
        "Nuolaidų pasirinkimai kitame žingsnyje": "Opcije popusta su u sljedećem koraku",
        "Pirkti papildomas renginio paslaugas": "Dodatne usluge",
        "PVM mokėtojo kodas": "PDV br",
        "Sektoriai": "Sektori",
        "Nuolaidas galėsi pritaikyti krepšelyje": "Popusti se mogu primijeniti u košarici",
        "Ši nuoroda negalioja.": "Veza nije važeća",
        "Bilietus į savo norimą renginį kviečiame įsigyti renginių namuose internete:": "Ulaznicu kupite u kući događaja:",
        "Įsigyti bilietą": "Kupi kartu",
        "Pardavimas sustabdytas": "Prodaja je obustavljena",
        "Pardavimo operacija nepavyko, krepšelis laukimo stadijoje. Jei norite iš naujo pradėti pardavimą, <1>spauskite čia</1>": "Operacija prodaje nije uspjela, kolica su na čekanju.  <1>kliknite ovdje</1>",
        "Įveskite kodą": "Unesite kod za otključavanje",
        "Taikyti": "Prijavite se",
        "Pasirink datą": "Odaberite datum",
        "Pasirink laiką": "Odaberite vrijeme",
        "Laikas": "Vrijeme",
        "Atsiskaitymas dovanų kuponu apmokėjimo lange": "Možete platiti poklon vaučerom u prozoru za plaćanje",
        "Dovanų kuponas sėkmingai pratęstas": "Produženje poklon vaučera je uspješno",
        "Suformuotos rezervacijos keisti negalite.": "Ne možete promijeniti već formiranu rezervaciju."
      }
};
