
/* injects from baggage-loader */

import i18next from 'i18next';

export const validations = {
    email_field_required: i18next.t('Prašome įvesti el. pašto adresą.'),
    address_field_required: i18next.t('Prašome įvesti adresą.'),
    required_field: i18next.t('Šis laukas yra privalomas.'),
    password_field_required: i18next.t('Prašome įvesti slaptažodį.'),
    password_field_complexity: i18next.t('Slaptažodį turi sudaryti mažiausiai 6 simboliai.'),
    password_field_confirmation: i18next.t('Nurodytos slaptažodžių reikšmės turi sutapti.'),
    birth_field_required: i18next.t('Prašome nurodyti Gimimo metus.'),
    city_field_required: i18next.t('Prašome nurodyti miestą.'),
    rules_field_required: i18next.t('Trūksta tik varnelės, kad galėtum tęsti!'),
    name_field_required: i18next.t('Prašome įvesti vardą.'),
    surname_field_required: i18next.t('Prašome įvesti pavardę.'),
    company_name_field_required: i18next.t('Prašome įvesti pavadinimą.'),
    company_code_field_required: i18next.t('Prašome įvesti įmonės kodą.'),
    company_city_field_required: i18next.t('Prašome įvesti miestą.'),
    company_city_field_length: i18next.t('Miestą turi sudaryti iki 100 simbolių.'),
    company_address_field_required: i18next.t('Prašome įvesti įmonės adresą.'),
    company_address_field_length: i18next.t('Įmonės adresą turi sudaryti iki 100 simbolių.'),
    company_code_length: i18next.t('Įmonės kodą turi sudaryti iki 20 simbolių.'),
    company_vat_code_length: i18next.t('PVM mokėtojo kodas turi būti sudarytas iš 11-14 simbolių.'),
    email_confirmation: i18next.t('Nurodyti el.pašto adresai turi sutapti.'),
    only_letters: i18next.t('Prašome pataisyti nurodytą reikšmę. Varde negali būti skaičių.'),
    start_with_plus: i18next.t('Prašome įvesti telefono numerį.'),
    email_field_check_again: i18next.t('Peržiūrėk savo nurodytą el. pašto adresą dar kartą.'),
    cash_amount_required: i18next.t('Prašome nurodyti pinigų sumą'),
    email_message_required: i18next.t('Prašome įvesti žinutę.'),
    only_numbers: i18next.t('Prašome pataisyti nurodytą reikšmę. Sumą turi sudaryti skaičiai.'),
    decimal_unit: i18next.t('Prašome pataisyti nurodytą reikšmę. Sumą po kablelio privalo sudaryti vienas arba du skaitmenys.'),
    cash_payment_below_cart_value: i18next.t('Nurodyta priimama suma yra mažesnė nei krepšelio suma.'),
    event_name_field_required: i18next.t('Prašome įvesti renginio pavadinimą.'),
    ticket_number_field_required: i18next.t('Prašome įvesti bilieto numerį.'),
    bank_account_field_required: i18next.t('Prašome įvesti banko sąskaitos numerį.'),
    card_number_field_required: i18next.t('Prašome įvesti kortelės numerį.'),
    ct_field_required: i18next.t('Prašome įvesti centus.'),
    eur_field_required: i18next.t('Prašome įvesti eurus.'),
    ticket_count_field_required: i18next.t('Prašome pasirinkti bilietų kiekį.'),
    presale_code_required: i18next.t('Prašome įvesti išankstinio pardavimo kodą.'),
    ticket_return_text: i18next.t('Prašome įvesti priežastį.'),
    post_code_required: i18next.t('Prašome įvesti pašto kodą.'),
    house_number_required: i18next.t('Prašome įvesti namo numerį.'),
    phone_number_valid: i18next.t('Prašome patikrinti telefono numerį. Turi prasidėti +370 ir sudaryti tik skaičiai'),
    phone_number_valid2: i18next.t('Telefono numeris turi prasidėti 370 ir susidaryti iš 11 skaitmenų.')
};
