
/* injects from baggage-loader */

/* tslint:disable:TS2695 */
export const en = {
    translation: {
        ' | kakava.lt': ' | kakava.lt',
        'Aaaaaah! Tokio puslapio nėra :(': 'Aaaaaah! This page does not exist :(',
        'Adresas': 'Address',
        'Akcija!': 'Sale!',
        'Akcija': 'Special offer',
        'Akcijos': 'Special offers',
        'Aktyvacijos klaida': 'Activation error',
        'Amžiaus cenzas': 'Age of consent',
        'Apie atlikėją': 'About performer',
        'Apie organizatorių': 'About event organizer',
        'Apie renginį': 'Event information',
        'Apmokėjimas': 'Payment',
        'Apmokėtas': 'Paid',
        'Apmokėti galėsite dovanų kuponu, kortele arba internetine bankininkyste': 'You will be able to pay with a gift voucher, card, or online banking',
        'Apmokėti': 'Pay',
        'Ar krepšelis apmokėtas?': 'Is the cart paid?',
        'Ar norite anuliuoti krepšelį?': 'Want to cancel the cart?',
        'Asmeniniai duomenys': 'Personal details',
        'Atraskite muzikos, teatro, kultūrinius ir sporto renginius, kurių bilietus įsigysite paprasčiau, greičiau ir patogiau per kakava.lt': 'Discover music, theater, cultural and sporting events and buy tickets easier, faster and more conveniently on kakava.lt',
        'Atsijungti': 'Log off',
        'Atsisiųsti pažymėtus bilietus': 'Download  checked tickets',
        'Atsisiųsti pažymėtus kuponus': 'Download the selected Gift cards',
        'Pridėti draudimą visiems bilietams': 'Add insurance to all tickets',
        'Bilietų draudimas': 'Ticket insurance',
        'Gaukite kompensaciją už nepanaudotą bilietą su bilietų draudimu!': 'Receive reimbursement for your unused ticket with ticket insurance!',
        'Plačiau apie sąlygas sužinokite čia': 'Please read more about terms and conditions here',
        'Atsisiųsti visus bilietus': 'Download all tickets',
        'Atsisiųsti visus kuponus': 'Download all Gift cards',
        'Atsisiųsti': 'Download',
        'Atšaukta': 'Cancelled',
        'Atšauktas renginys': 'Cancelled event',
        'Atšaukti': 'Cancel',
        'Bilietai išsiųsti': 'Tickets sent',
        'Bilietai jau tavo!': 'Tickets are yours!',
        'Bilietai nerasti': 'Tickets not found',
        'Bilietai spausdinimui': 'Tickets for printing',
        'Bilietai sėkmingai išsiųsti draugui!': 'Tickets successfully sent to a friend!',
        'Bilietai į renginius internetu: koncertai, teatras, pramogos': 'Online tickets for events: concerts, theater, entertainment, sports',
        'Bilietai į renginius – paprasčiau nei paprastai! Greitas bilietų pirkimo procesas – su kakava.lt! Muzika, teatras, kultūros ir sporto renginiai.': 'Event tickets – simpler than usual! Fast ticket buying process – with kakava.lt! Music, theater, cultural and sporting events.',
        'Bilietai': 'Tickets',
        'Bilietams ir sąskaitai atsisiųsti liko': 'Time remaining to download the tickets and invoice',
        'Bilietas': 'Ticket',
        'Bilieto kaina': 'Ticket price',
        'Bilietus išsiuntėme el. paštu': 'An email with your tickets has been sent',
        'Bilietus į populiariausius muzikos, teatro, kultūros ir sporto renginius įsigykite greičiau ir paprasčiau su kakava.lt – pažangiu bilietų platintoju.': 'Get tickets to the most popular music, theater, culture and sports events faster and easier with kakava.lt – an innovative ticket seller.',
        'Bilietų kainos': 'Prices',
        'Bilietų pardavimas pasibaigęs': 'Ticket sales ended',
        'Buto Nr.': 'Apartment No',
        'Dalinkis su draugais': 'Share with your friends',
        'Data nuo iki': 'Date',
        'Data': 'Date',
        'Daugiau': 'More',
        'Dirbame kasdien 8-22 val.': 'Working hours: daily from 8:00 to 22:00',
        'Dovanų kuponai': 'Gift cards',
        'Duomenys atnaujinti': 'Data updated',
        'Duomenys sąskaitai faktūrai': 'Invoice details',
        'Durys atidaromos': 'Door opens before',
        'Eilė': 'Row',
        'El. laiškas su slaptažodžio priminimo nuoroda išsiųstas.': 'Email with password reminder link was sent.',
        'El. paštas sąskaitai': 'Email for invoice',
        'El. paštas': 'Email',
        'Esi užsiregistravęs?': 'Are you signed up?',
        'Filtruoti renginius': 'Filter events',
        'Filtruoti': 'Filter',
        'Fizinis asmuo': 'Individual',
        'Galimos dovanų kuponų kainos': 'Available Gift card prices',
        'Gimimo metai': 'Year of birth',
        'Grąža': 'Change',
        'Grįžti atgal': 'Back',
        'Grįžti į pradinį langą': 'Back to homepage',
        'Grįžti į renginį': 'Back to event',
        'Ieškokite miesto': 'Search for a city',
        'Ieškoti renginių': 'Search for events',
        'Ieškoti': 'Search',
        'Iki': 'To',
        'Informacija apie renginį': 'Event information',
        '<0>Informuojame, kad šioje svetainėje naudojami slapukai (angl. cookies). Tęsdami naršymą šioje svetainėje, jūs sutinkate su mūsų Privatumo politika.</0>Daugiau apie <2>Privatumo politiką</2>.': '<0>This website uses cookies. By continuing to browse this website, you agree to our privacy policy.</0> More about <2>Privacy Policy</2>.',
        'Informuojame, kad šioje svetainėje naudojami slapukai (angl. cookies). Tęsdami naršymą šioje svetainėje, jūs sutinkate su mūsų Privatumo politika.': 'This website uses cookies. By continuing to browse this website, you agree to our privacy policy.',
        'Internetinė bankininkystė': 'Online banking',
        'Interneto puslapis': 'Website',
        'Iš viso': 'Total',
        'Išparduota': 'Sold out',
        'Išsaugoti': 'Save',
        'Išvalyti duomenis': 'Clear details',
        'Išvalyti': 'Clear',
        'Jau <1>{{eventDate}}</1>d. <3>{{locationName}}</3> išvysite „<5>{{eventTitle}}</5>“. Renginio bilietus įsigysite greičiau per kakava.lt': 'Already on <1>{{eventDate}}</1>d. <3>{{locationName}}</3> you will see „<5>{{eventTitle}}</5>“. Buy event tickets faster on kakava.lt',
        'Jei dar nesi sumokėjęs, <1>spausk čia</1>': 'If you have not paid yet, <1>click here</',
        'Juridinis asmuo': 'Legal entity',
        'Kaina nuo': 'Price from',
        'Kaina': 'Price',
        'Kakava LT © 2018': 'Kakava LT © 2018',
        'Kasa': 'Box office',
        'Keisti sektorių': 'Change the sector',
        'Keisti vietas': 'Change seats',
        'Keisti vietą ir laiką': 'Change place and time',
        'Skirtingos lokacijos': 'Multiple locations',
        'Kita': 'Other',
        'Kiti sektoriai': 'Other sectors',
        'Kiti siūlomi renginiai': 'Other available events',
        'Koncertų, teatro, kultūros ir sporto renginių naujienos bei jų bilietai – vienoje vietoje. Susipažinkite su kakava.lt': 'Tickets for new concerts, theater, culture and sports events in one place. Learn about kakava.lt',
        'Kontaktai': 'Contacts',
        'Kortelės kodas': 'Card code',
        'Krepšelis laukia apmokėjimo.': 'Cart is pending payment.',
        '<0>Krepšelis tuščias</0><1>ir išalkęs bilietų. Papildom?</1>': '<0>Cart is empty</0><1>and hungry for tickets. Let’s fill it?</1>',
        'Krepšelis': 'Cart',
        'Kvitas nerastas': 'Receipt not found',
        'Kuponai': 'Gift cards',
        'kuponai': 'gift cards',
        'kuponas': 'gift card',
        'Kuponas': 'Gift card',
        'kuponų': 'Gift cards',
        'Kuponai jau tavo!': 'The Gift cards are yours!',
        'Kuponus išsiuntėme el. paštu': 'We have sent your Gift cards by e-mail',
        'Lange bilietai atsiras iškart, kai sugeneruosime.': 'Tickets will appear in the window immediately when we generate them.',
        'Liko laiko': 'Time remaining',
        'Lvovo g. 89-79, Vilnius': 'Lvovo str. 89-79, Vilnius',
        'Kalba': 'Language',
        'Mano bilietai': 'My tickets',
        'Mano duomenys': 'My details',
        'Mano krepšelis': 'My cart',
        'Mano mėgstami renginiai': 'My favourite events',
        'Mažiau': 'Less',
        'Miestas': 'City',
        'Mobilieji mokėjimai': 'Mobile payments',
        'Mokėjimo kortelė': 'Payment card',
        'Mokėjimui atlikti turi 20 minučių': 'You have 20 minutes for payment',
        'Mėgstami renginiai': 'Favorite events',
        'Namo Nr.': 'House No',
        'Naujas slaptažodis': 'New password',
        'Naujiena': 'New',
        'Ačiū, kad prenumeruoji kakava.lt naujienlaiškį. Prisijunk prie savo pašto dėžutės ir patvirtink kad sutinki gauti kakava.lt  naujienas.': 'Thank you for subscribing to the kakava.lt newsletter. Log in to your mailbox and confirm that you agree to receive kakava.lt news.',
        'Tavo patvirtinimą gavome, nuo šiol visas naujienas sužinosi pirmas!': 'We received your confirmation, from now on you will be the first to know all the news!',
        'Naujienlaiškio aktyvacija': 'Newsletter activation',
        'Naujienlaiškio aktyvacija nepavyko.': 'Newsletter activation failed.',
        'Naujienlaiškis': 'Newsletter',
        'Naujienos': 'News',
        'Ne': 'No',
        'Neapmokėtas': 'Unpaid',
        'Nerasta': 'Not found',
        'Nesutampa su {fieldLabel}': 'Doesn\'t match with {fieldLabel}',
        'Neturi prisijungimo?': 'New to kakava.lt? Sign up!',
        'Nori rinktis daugiau renginių?': 'Want to choose more events?',
        'Norint įsigyti daugiau nei 10 bilietų, prašome kreiptis': 'To purchase more than 10 tickets, contact',
        'Noriu gauti kakava.lt  ir / arba renginio organizatoriaus naujienas': 'I want to get kakava.lt  and / or promoter\'s newsletter',
        'Nesutinku, kad mano kontaktiniai duomenys būtų naudojami panašių prekių ar paslaugų rinkodarai': 'I do not agree for my contact information to be shared for marketing similar products or services',
        'Pritaikyti nuolaidą': 'Apply a discount',
        'Keisti nuolaidą': 'Change discount',
        'Norėdamas pasirinkti vietą, spausk ant jos salės plane': 'To choose a seat, click it in the venue plan',
        'Nuo brangiausio iki pigiausio': 'Price: highest first',
        'Nuo greičiausiai prasidedančio': 'Time: starting soonest',
        'Nuo pigiausio iki brangiausio': 'Price: lowest first',
        'Nuo vėliausiai prasidedančio': 'Time: starting latest',
        'Nuo': 'From',
        'Nuolaida pritaikyta': 'Discount applied',
        'Nuolaidos kodas': 'Discount code',
        'Nuolaidos': 'Discounts',
        'Nurodyk, kur siųsti bilietus': 'Let us know where to send tickets',
        'Nurodyta priimama suma yra mažesnė nei krepšelio suma.': 'The specified accepted amount is less than the amount of the cart.',
        'Nurodytas neteisingas el.pašto adresas.': 'Invalid email address.',
        'Nurodyti el.pašto adresai turi sutapti.': 'The specified email addresses must match.',
        'Nurodytos slaptažodžių reikšmės turi sutapti.': 'The specified password values must match.',
        'O ne! Apmokėjimas nepavyko. Pasirink kitą mokėjimo būdą ir pamėgink dar kartą.': 'Oh no! Payment failed. Choose another payment method and try again.',
        'O ne! Kažkas nutiko. Klaida yra dalis progreso, atkreipsime į ją dėmesį, o tu pamėgink dar kartą.': 'Oh no! Something went wrong. The error is part of the progress, we will focus on it, and you try again.',
        'O ne! Neradome nieko, kas atitiktų filtrą. Pamėgink nurodyti kitą reikšmę.': 'Oh no! Nothing found to match the filter. Try another value.',
        'O ne! Nieko neradome. Paieškai reikia mažiausiai trijų simbolių, tad pamėgink dar kartą.': 'Oh no! Nothing found. Search requires at least three characters, try again.',
        'O ne! Nieko neradome. Pamėgink patikrinti rašybą arba ieškoti pagal atlikėjo, renginio, organizatoriaus ar arenos pavadinimą.': 'Oh no! Nothing found. Try spell check or search by artist, event, organizer or venue name.',
        'OK, mokėjimą atlikote': 'OK, you made the payment',
        'Oops. Matom, kad mokėjimas atmestas. Pirmas blynas kartais prisvyla – bandom apmokėti kitu būdu?': 'Oops. We see the payment was declined. First time is not always successful – let’s try to pay in some other way?',
        'Operacija baigta.': 'Operation completed.',
        'Ouch. Atsitrenkėm į klaidą iš banko, bičiuli. Bandyk kitą mokėjimo variantą.': 'Ouch. We hit the bank error, buddy. Try another payment option.',
        'Ouch. Atsitrenkėm į klaidą, bičiuli. Pirmas blynas kartais prisvyla – bandom apmokėti kitu būdu?': 'Ouch. We hit the error, buddy. First time is not always successful – let’s try to pay in some other way?',
        'Ouch. Atsitrenkėm į neaiškią klaidą iš banko, bičiuli. Bandyk kitą mokėjimo variantą.': 'Ouch. We hit an unclear bank error, buddy. Try another payment method.',
        'Pasirink kuponus': 'Choose the Gift cards',
        'Pasirinkite dovanų kuponą': 'Choose the Gift card',
        'POS Klaida spausdinant bilietus': 'POS error while printing the tickets',
        'POS Klaida spausdinant kvitą': 'POS error while printing the receipt',
        'POS Klaida': 'POS error',
        'POS Spausdinimas': 'POS printing',
        'PVM kodas': 'VAT number',
        'PVM mokėtojo kodas turi būti sudarytas iš 11-14 simbolių.': 'The VAT number must be 11-14 characters long.',
        'Paieška': 'Search',
        'Paieškos rezultatai pagal užklausą': 'Search results by query',
        'Pakartok el. paštą': 'Re-enter your email',
        'Pakartok naują slaptažodį': 'Re-enter the new password',
        'Pakeisti slaptažodį': 'Change the password',
        'Pamiršai slaptažodį? Kam nepasitaiko, bičiuli. Įvesk savo el. pašto adresą.': 'Forgot your password? Things just happen, buddy. Enter your email.',
        'Pamiršau slaptažodį': 'Forgot password',
        'Pardavimų ataskaita': 'Sales report',
        'Pasibaigęs': 'Event is expired',
        'Pasirink apmokėjimo būdą': 'Choose payment method',
        'Pasirink ataskaitos laikotarpį': 'Select report period',
        'Pasirink bilietus': 'Select tickets',
        'Pasirink bilietą': 'Select ticket',
        'Pasirink miestą': 'Choose the city',
        'Pasirink nuolaidą': 'Select a discount',
        'Pasirink sektorių': 'Choose a sector',
        'Pasirink tau priklausančią nuolaidą': 'Choose your discount',
        'Pasirink vietą': 'Select a seat',
        'Pasirink': 'Select',
        'Pavardė': 'Last name',
        'Pašalinti': 'Remove',
        'Pašto kodas': 'Post code',
        'Per ilgas (iki {count, number} {count, plural, one {simbolio} other {simbolių}})': 'Too long (to {count, number} {count, plural, one {symbol} other {symbols}})',
        'Per trumpas (bent {count, number} {count, plural, one {simbolis} other {simboliai}})': 'Too short (at least {count, number} {count, plural, one {symbol} other {symbols}})',
        'Perkamiausi renginiai': 'Best sellers',
        'Perkelta': 'Moved',
        'Perkeltas renginys': 'Moved event',
        'Persiųsk bilietą draugui': 'Forward a ticket to your friend',
        'Persiųsk kuponą draugui': 'Forward a Gift card to a friend',
        'Pertraukos': 'Breaks',
        'Peržiūrėk savo nurodytą el. pašto adresą dar kartą.': 'Check your email address again.',
        'Peržiūrėti visus': 'View all',
        'Pirk su MoQ ir 2 Eur susigrąžink atgal!': 'Pirk su MoQ ir 2 Eur susigrąžink atgal!',
        'Pirkti bilietus VIP sąlygomis': 'Buy tickets as VIP',
        'Pirkti bilietus įprastomis sąlygomis': 'Buy tickets as simple user',
        'Pirkti bilietus': 'Buy tickets',
        'Pirkti kuponus': 'Buy Gift cards',
        'Pirkėjo el. paštas (neprivalomas)': 'Buyer\'s email (optional)',
        'Pirkėjo el. paštas': 'Buyer\'s email',
        'Pirkėjo mob. telefono nr.': 'Buyer\'s mob. phone number.',
        'Pirkėjo pavardė': 'Buyer\'s lastname',
        'Pirkėjo vardas': 'Buyer\'s name',
        'PIRKTI VIP KLUBO/LOŽĖS BILIETUS': 'PURCHASE VIP CLUB/LODGE TICKETS',
        'Platintojas veikia kaip atsiskleidęs tarpininkas. Atsakomybę dėl įvykusio renginio ir jo kokybės prisiima Renginio Organizatorius. Dėl pinigų grąžinimo renginio nukėlimo ar atšaukimo atveju sprendimą priima organizatorius.': 'The Ticket seller (kakava.lt) operates as a disclosed agent. Responsibility for the event and its quality is borne by the event organiser. The organiser makes decision regarding the refunding arrangements in case of cancellation or postponement of the event.',
        'Plačiau': 'More',
        'Populiarus': 'Popular',
        'Pradėkime nuo pradžių': 'Let\'s start from the beginning',
        'Pranešk draugams!': 'Tell your friends!',
        'Prašome nurodyti Gimimo metus.': 'Specify the year of birth.',
        'Prašome nurodyti miestą.': 'Specify the town.',
        'Prašome nurodyti pinigų sumą': 'Specify the amount of money',
        'Prašome įvesti telefono numerį.': 'Specify the phone number.',
        'Prašome pasirinkti banką.': 'Choose the bank.',
        'Prašome pasirinkti mokėjimo būdą': 'Choose the payment method',
        'Prašome pasirinkti mokėjimo būdą.': 'Choose the payment method.',
        'Prašome pataisyti nurodytą reikšmę. Sumą po kablelio privalo sudaryti vienas arba du skaitmenys.': 'Correct the specified value. The decimal part must include one or two digits.',
        'Prašome pataisyti nurodytą reikšmę. Sumą turi sudaryti skaičiai.': 'Correct the specified value. The sum must consist of numbers.',
        'Prašome pataisyti nurodytą reikšmę. Varde negali būti skaičių.': 'Correct the specified value. The name cannot contain numbers.',
        'Prašome įvesti adresą.': 'Enter the address.',
        'Prašome įvesti el. pašto adresą.': 'Enter the email.',
        'Prašome įvesti miestą.': 'Enter the city.',
        'Prašome įvesti pavadinimą.': 'Enter the name.',
        'Prašome įvesti pavardę.': 'Enter the last name.',
        'Prašome įvesti slaptažodį.': 'Enter the password.',
        'Prašome įvesti vardą.': 'Enter the name.',
        'Prašome įvesti įmonės adresą.': 'Enter your business address.',
        'Prašome įvesti įmonės kodą.': 'Enter your company code.',
        'Prašome įvesti žinutę.': 'Enter your message.',
        'Priimta suma grynais': 'Accepted amount in cash',
        'Prisijungimas su Facebook nepavyko': 'Login with Facebook failed',
        'Prisijungimas': 'Log in',
        'Prisijungimo klaida': 'Login error',
        'Prisijungti': 'Log in',
        'Prisijunk prie mūsų': 'Join us',
        'Prisijunk įvesdamas savo prisijungimo duomenis – galėsi išsisaugoti patikusius renginius bei įsigyti bilietus.': 'Sign in by entering your login details – you can save your favorite events and buy tickets.',
        'Prisijunk': 'Log in',
        'Prisiminti mane': 'Remember me',
        'Pritaikyta nuolaidų': 'Discounts applied',
        'Privalomas laukas': 'Mandatory field',
        'Redaguoti': 'Edit',
        'Registracija sėkminga': 'You have successfully signed up',
        'Registracija sėkminga. Patikrink paštą ir aktyvuok savo vartotoją.': 'You have successfully signed up. Check your email and activate your user.',
        'Registracijos klaida': 'Registration error',
        'Registruokis': 'Register',
        'Registruotis': 'Register',
        'Reikės sąskaitos faktūros': 'Need an invoice',
        'Renginiai': 'Events',
        'renginiai': 'events',
        'Renginio akimirkos': 'Moments of the event',
        'Renginio pradžia': 'Start',
        'Renginio tipas': 'Event type',
        'Renginio trukmė': 'Duration',
        'renginių': 'events',
        'Renginių bilietai – pigiau! Geriausius muzikos, teatro, sporto ir kultūros renginių pasiūlymus ir kitas akcijas rasite čia.': 'Event tickets – cheaper! Find the best music, theater, sports and cultural events and other discounts here.',
        'Renginys': 'Event',
        'renginys': 'Event',
        'Rezervacijos dokumentas': 'Reservation document',
        'Rinkis bilietus iš naujo': 'Re-select tickets',
        'Rinktis kuponą': 'Choose a Gift card',
        'Rinktis vietą ir laiką': 'Choose place and time',
        'Rūšiuoti pagal': 'Sort by',
        'Saugoti': 'Save',
        'Saugumo sumetimais bilietų ir sąskaitų faktūrų neberodome': 'For security reasons, we no longer show tickets and invoices',
        'Sektorius': 'Sector',
        'Senas slaptažodis': 'Old password',
        'Sesija baigėsi – prisijunk iš naujo.': 'The session is over – sign in again.',
        'Sesijos laikas baigėsi': 'Session timed out',
        'Siųsti atstatymo nuorodą': 'Send a reset link',
        'Siųsti bilietus el.paštu': 'Send tickets by email',
        'Siųsti kuponus el.paštu': 'Send coupon by email',
        'Siųsti pažymėtus bilietus draugui': 'Share checked tickets with your friend',
        'Siųsti pažymėtus kuponus draugui': 'Send tagged Gift cards to a friend',
        'Siųsti': 'Send',
        'Skirtingos vietos': 'Different locations',
        'Slaptažodis pakeistas sėkmingai.': 'Password changed successfully.',
        'Slaptažodis pakeistas': 'Password changed',
        'Slaptažodis': 'Password',
        'Slaptažodį turi sudaryti mažiausiai 6 simboliai.': 'The password must be at least 6 characters long.',
        'Slaptažodžio atkūrimas': 'Password recovery',
        'Slaptažodžio keitimas nepavyko': 'Password change failed',
        'Slaptažodžio keitimas': 'Password change',
        'Slaptažodžio priminimas': 'Password reminder',
        'Spausdinti bilietus': 'Print tickets',
        'Spausdinti kvitą': 'Print receipt',
        'Spausdinti kuponus': 'Print Gift cards',
        'Suma': 'Total',
        'Supakuok savo bilietus': 'Gift a ticket',
        'Supakuoti': 'Wrap ticket',
        'Susikurk naują slaptažodį ir jį pakartok.': 'Create a new password and repeat it.',
        'Susipažinau su pirkimo <1>taisyklėmis</1>': 'I have read the purchase <1>rules</1>',
        'Susipažinau su <1>taisyklėmis</1>': 'I have read the <1>rules</1>',
        'Susisiek': 'Contact us',
        'Sutinku': 'I Agree',
        'Svarbi informacija': 'Important information',
        'Sąskaita faktūra': 'Invoice',
        'Taip': 'Yes',
        'Tam, kad taptum kakava.lt vartotoju, mes turime turėti tavo el.pašto adresą. Neturėdami tavo el. pašto, negalėsime tau išsiųsti bilietų, pateikti specialių pasiūlymų ar kitos informacijos. Todėl spausk dar kartą mygtuką, sutik mums duoti savo duomenis, ir prisijunk prie mūsų!': 'In order to become a kakava.lt user, we need to have your email address. Without your email we will not be able to send you tickets, special offers or any other information. So click the button again, agree to give us your details and join us!',
        'Tavo bilietai': 'Your tickets',
        'Tavo duomenys atnaujinti sėkmingai.': 'Your details have been updated successfully.',
        'Tavo el. paštas': 'Your email',
        'Tavo kakava.lt nupirkti bilietai į renginį tuoj kažką labai pradžiugins. Įrašyk sveikinimo tekstą ir nusiųsk bilietą draugui ar artimajam!': 'Make someone happy with the event tickets you bought on kakava.lt. Enter the greeting text and send a ticket to your friend or loved one!',
        'Tavo kakava.lt nupirkti kuponai tuoj kažką labai pradžiugins. Įrašyk sveikinimo tekstą ir nusiųsk kuponą draugui ar artimajam!': 'Make someone happy with the gift coupon you bought on kakava.lt. Enter the greeting text and send a coupon to your friend or loved one!',
        'Tavo krepšelis': 'Your cart',
        'Tavo kuponai': 'Your Gift cards',
        'Tavo žinutė draugui': 'Your message to a friend',
        'Tačiau primename, kad savo bilietus ir sąskaitą faktūrą visada rasi el. pašte.': 'Remember that you can always will find your tickets and invoice in your email..',
        'Trumpam užsnūdai? Sesija baigėsi – prisijunk iš naujo.': 'Having a nap? The session is over – log in again.',
        'Trūksta tik varnelės, kad galėtum tęsti!': 'The check mark is missing to continue!',
        'Turi būti pažymėta': 'Must be checked',
        'Tęsti apmokėjimą': 'Continue payment',
        'Tęsti': 'Continue',
        'Uh-oh. Kortelė NEDRAUGAUJA su internetiniais pirkimais. Savo banke pakeisk kortelės nustatymus, kad leistų atsiskaityti internetu.': 'Uh-oh. The card is not friendly to online purchases. Change the card settings at your bank to allow online payments.',
        'Užimtos vietos': 'Occupied seats',
        'Užsiregistruok': 'Sign up',
        'Užsiregistruok: prisiminsime tave ir nereikės kaskart vesti duomenų iš naujo.': 'Save your time - sign up! We will remember you next time.',
        'Užsisakyti': 'Subscribe',
        'VIP ložės ir klubai': 'VIP lounges and clubs',
        'Vaikai įleidžiami nemokamai': 'Children are admitted free of charge',
        'Valio! Slaptažodis sėkmingai pakeistas.': 'Hooray! Password changed successfully.',
        'Vardas': 'Name',
        'Vartotojas aktyvuotas. Prašome prisijungti.': 'The user is activated. Login.',
        'Vartotojas nėra aktyvuotas. Registracijos metu tau išsiuntėme el.laišką, prašome paspausti jame esančią nuorodą. Tada galėsi prisijungti.': 'The user is not activated. We sent you an email when signing up, click on the verification link in it. You then can login.',
        'Vartotojo aktyvacija': 'User activation',
        'Vartotojo aktyvavimas nepavyko.': 'User activation failed.',
        'Vartotojo vardas arba slaptažodis yra neteisingi.': 'The username or password is incorrect.',
        'Vieta': 'Place',
        'Visi sektoriai': 'All sectors',
        'Visi': 'All',
        '<0>Viskas, ko reikia – sekundė kantrybės! Lange bilietai atsiras iškart, kai sugeneruosime.</0><1>Taip pat bilietus atsiųsim el. paštu bei visada rasi savo paskyroje.</1>': 'All you need is a second of patience! Tickets will appear on the window as soon as we generate them.<1></1>We will also send your tickets by email and you will always find them on your account.',
        '<0>Viskas, ko reikia – sekundė kantrybės! Lange bilietai atsiras iškart, kai sugeneruosime.</0><1>Taip pat bilietus atsiųsime el. paštu.</1>': '<0>All you need is a second of patience! Tickets will appear on the window as soon as we generate them.</0><1>We will also send your tickets by email.</1>',
        '<0>Viskas, ko reikia – sekundė kantrybės! Lange kuponai atsiras iškart, kai sugeneruosime.</0><1>Taip pat kuponus atsiųsim el. paštu bei visada rasi savo paskyroje.</1>': '<0>All you need is a second of patience! The Gift cards will appear in the window as soon as we generate them.</0><1>We will also send the Gift cards by e-mail plus you can always find them in your account.</1>',
        '<0>Viskas, ko reikia – sekundė kantrybės! Lange kuponai atsiras iškart, kai sugeneruosime.</0><1>Taip pat kuponus atsiųsime el. paštu.</1>': '<0>All you need is a second of patience! The Gift cards will appear in the window as soon as we generate them.</0><1> We will also send the Gift cards by e-mail.</1>',
        'arba': 'or',
        'bilietai': 'tickets',
        'bilietas': 'ticket',
        'bilietų': 'tickets',
        'iki': 'to',
        'iš': 'of',
        'kakava-lt.pdf': 'kakava-lt.pdf',
        'kakava-lt_ataskaita_': 'kakava-lt_report_',
        'kakava-lt_dienos-ataskaita.pdf': 'kakava-lt_report-of-the-day.pdf',
        'kakava-lt_rezervacija.pdf': 'kakava-lt_reservation.pdf',
        'kakava.lt | Kultūrinio gyvenimo vitaminai': 'kakava.lt Vitamins of Cultural Life',
        'metai': 'years',
        'metų amžiaus': 'years old',
        'metų': 'years',
        'min.': 'min.',
        'nauju slaptažodžiu': 'with new password',
        'ne': 'no',
        'neprivalomas': 'optional',
        'nuo brangiausio iki pigiausio': 'price: highest first',
        'nuo greičiausiai prasidedančio': 'time: starting soonest',
        'nuo pigiausio iki brangiausio': 'price: lowest first',
        'nuo vėliausiai prasidedančio': 'time: starting latest',
        'nėra': 'none',
        'Čia patogiai pateiksime tavo išsaugotus renginius. Radęs patinkantį renginį, spustelk širdutę jo išsaugojimui.': 'Here you will find your favorite events. Found one? Click the heart to save it!',
        'Čia patogiai rasi savo jau įsigytus bilietus. Kaskart nusipirkus bilietus į patinkantį renginį, bilietai bus čia.': 'Here you will find all the tickets you bought.',
        'Įdėti į krepšelį': 'Add to cart',
        'Įmonės kodas': 'Company number',
        'Įmonės pavadinimas': 'Company name',
        'Įpakavimo kaina': 'Wrapping price',
        'Įvesk el. pašto adresą': 'Enter your email',
        'Įveskite nuolaidos kodą': 'Enter the discount code',
        'Įvykęs renginys': 'Event is over',
        'Jūs nematote laisvų/ užimtų vietų. Renginiui vietos parenkamos automatiškai. Jei parinktos vietos netinka, spauskite „Keisti vietas“': 'You do not see free/ occupied seats. Seats are selected automatically for the event. If the selected seats are not suitable, click „Change seats“',
        'Šis laukas yra privalomas.': 'This field is mandatory.',
        '„<1>{{eventTitle}}</1>“ jau greitai jūsų mieste! Bilietus į artimiasią renginį įsigykite greičiau ir paprasčiau per kakava.lt.': '„<1>{{eventTitle}}</1>“ soon in your city! Get tickets for the nearest event faster and easier with kakava.lt.',
        '„{{eventTitle}}“ jau greitai jūsų mieste! „{{eventTitle}}“ bilietai į renginį greičiau ir paprasčiau per kakava.lt.': '„{{eventTitle}}“ soon in your city! „{{eventTitle}}“ tickets to an event faster and easier with kakava.lt.',
        'Jūsų naršyklė blokuoja Google': 'Your browser blocking Google',
        'Ginčai dėl sutarties netinkamo vykdymo ar nevykdymo ne teisme nagrinėjami Lietuvos Respublikos vartotojų teisių apsaugos įstatymo nustatyta tvarka Valstybinėje vartotojų teisių apsaugos tarnyboje, adresu Vilniaus g. 25, 01402 Vilnius, el. p. tarnyba@vvtat.lt, tel. (8 5) 262 67 51, faks. (8 5) 279 1466, interneto svetainė www.vvtat.lt. Elektroniniu būdu prašymą galite pateikti per EGS platformą http://ec.europa.eu/odr/.': 'Disputes regarding improper performance or non-performance of the contract out of court shall be examined in accordance with the procedure established by the Law on Consumer Rights Protection of the Republic of Lithuania at the State Consumer Rights Protection Service, Vilniaus st. 25, 01402 Vilnius, email tarnyba@vvtat.lt, tel. (8 5) 262 67 51, fax. (8 5) 279 1466, website www.vvtat.lt. You can apply electronically via the EGS platform http://ec.europa.eu/odr/.',
        'Telefono nr.': 'Phone no.',
        'Apmokėta dovanų kuponu': 'Paid with Gift card',
        'Dovanų kuponas': 'Gift card',
        'Įveskite dovanų kupono numerį': 'Enter the Gift card number',
        'Dovanų kupono numeris susideda tik iš skaičių': 'The Gift card number consists only of numbers',
        'Toliau': 'Continue',
        'Dovanų kupono Nr.:': 'Gift card number:',
        'Suma:': 'Amount:',
        'Ačiū, Dovanų kuponas panaudotas': 'Thank you, the Gift card is used',
        'Dovanų kuponais sumokėta:': 'Gift card were used to pay for:',
        'Liko mokėti:': 'Remain to be paid:',
        'Dovanų kupono Nr.': 'Gift card number',
        'Pridėti dovanų kuponą': 'Add the Gift card',
        'Prisijunk su Facebook': 'Log in with Facebook',
        'Prisijunk su Google': 'Log in with Google',
        'Susipažinau su pirkimo taisyklėmis': 'I have read the rules of purchase',
        'Susipažinau su taisyklėmis': 'I have read the rules',
        'Susipažinau su pirkimo': 'I have read the rules',
        'taisyklėmis': 'of purchase',
        'Su MoQ atgauk 1%* (išimtys <1>taisyklėse</1>)': 'With MoQ get back 1% * (exceptions <1>in the rules</1>)',
        'Įveskite Dovanų kupono kodą': 'Enter the Gift coupon code',
        'Dovanų kupono numeris susideda iš 14 skaitmenų': 'The Gift coupon code consists of 14 digits',
        'Dovanų kupono kodas:': 'Gift coupon number:',
        'Dovanų kupono kodas': 'Gift coupon number',
        'Bilietų grąžinimas': 'Refund of tickets',
        'Laukiame apmokėjimo': 'Waiting for payment',
        'Laukiame krepšelio apmokėjimo. Prašome neuždaryti lango.': 'Waiting for your cart payment. Please do not close the window.',
        'Įvesk išankstinio pardavimo kodą': 'Enter the pre-sale code',
        'Išankstinio pardavimo kodas': 'Pre-sale code',
        'Išankstinio pardavimo kodas neteisingas.': 'The pre-sale code is wrong',
        'Kodas': 'Code',
        'Patvirtinti': 'Confirm',
        'Grąžinti': 'Refund',
        'Ar daryti grąžinimą?': 'Do make a refund?',
        'Ar grąžinimas atliktas?': 'Is the refund done?',
        'Atsidarykite Grąžinimo dokumentą (parsiųstas .pdf failas) ir atlikite pinigų grąžinimą klientui.': 'Open the Refund Document (downloaded .pdf file) and make a refund to the customer.',
        'Bilietai grąžinti': 'Tickets returned',
        'Ar tikslinti grąžinamų bilietų informaciją?': 'Do need to update ticket information?',
        'Įveskite grąžinamo bilieto numerį': 'Please enter your refund ticket number',
        'Bilieto numeris susideda tik iš skaičių': 'The ticket number consists of numbers only',
        'Bilieto numeris': 'Ticket number',
        'Pridėti bilietą': 'Add a ticket',
        'Bilieto Nr.:': 'Ticket no:',
        'Grąžinamų bilietų informacija': 'Refund ticket information',
        'Renginiai, kurių bilietus galima grąžinti': 'Events for which tickets are refundable',
        'Šiuo metu nėra renginių, kurių bilietus galima grąžinti': 'There are currently no events, for which tickets are refundable',
        'Grynieji pinigai / Mokėjimo kortelė': 'Cash / Payment card',
        'Renginiai pagal kategorijas': 'Events by category',
        'Apie festivalį': 'About the festival',
        '„{{groupTitle}}“ jau greitai jūsų mieste! Bilietus į artimiausią renginį įsigykite greičiau ir paprasčiau per kakava.lt.': '„{{groupTitle}}“ is coming to your city soon! Buy tickets to the nearest event faster and easier at kakava.lt.',
        'Festivaliai': 'Festivals',
        'Kaina ': 'Price ',
        'Nemokamas': 'Free',
        'Gauti bilietus': 'Get tickets',
        'Asmeniniai pasiūlymai': 'Personal discounts',
        'Pagalba kasoms': 'Box office support',
        'Pristatymo paslauga': 'Physical delivery',
        'Gatvė': 'Street',
        'Komentaras': 'Comment',
        'Papildomos paslaugos': 'Additional services',
        'Klubo/ložės duomenys': 'Club/lodge data',
        'Kodas/Kortelės nr.': 'Code/card no.',
        'Grįžti': 'Close',
        'Klubo/ložės pagal nurodytus duomenis rasti nepavyko.': 'Club/lodge can\'t be found.',
        'Miestą turi sudaryti iki 100 simbolių.': 'City must be up to 100 characters long.',
        'Įmonės adresą turi sudaryti iki 100 simbolių.': 'Company address must be up to 100 characters long.',
        'Įmonės kodą turi sudaryti iki 20 simbolių.': 'Company number must be up to 20 characters long.',
        'Sumokėta': 'Paid',
        'Bilietų kiekio keisti negalite': 'It is impossible to change the number of tickets',
        'Kakava LT, Žalgirio 135, Vilnius, Lietuva': 'Kakava LT, Žalgirio 135, Vilnius, Lithuania',
        'Pridėti į Apple Wallet': 'Add to Apple Wallet',
        'Pridėti bilietus į Apple Wallet': 'Add tickets to Apple Wallet',
        'Šią svetainę saugo reCAPTCHA ir Google. <1>Privatumo politika</1> bei <3>Paslaugų teikimo sąlygos</3>.': 'This site is protected by reCAPTCHA and the Google. <1>Privacy Policy</1> and <3>Terms of Service</3>.',
        'Rezervacijų apmokėjimas': 'Reservation payment',
        'Įveskite rezervacijos numerį': 'Enter reservations number',
        'Rezervacijos numeris susideda tik iš skaičių': 'The reservation number consists of numbers only',
        'Rezervacijos numeris': 'Reservation number',
        'Tik Jūsų sutikimu mes naudojame slapukus, su tikslu pagerinti Jūsų naršymo kokybę bei tobulinti mūsų paslaugų turinį bei naršymo saugumą. Be to, būtinieji slapukai įgalina pagrindines mūsų svetainės funkcijas; ji negalėtų tinkamai veikti be šių slapukų, todėl šiems slapukams Jūsų sutikimas nėra būtinas; juos galima išjungti tik pakeitus naršyklės nuostatas.': 'We use cookies only with your consent, with the aim of improving the quality of your browsing and, likewise, the content of our services and your browsing security. Besides, necessary cookies enable the main functions of our website; it would not be able to function properly without these cookies, so your consent is not necessary for these cookies; they can only be disabled by changing your browser settings.',
        'Tik Jūsų sutikimu mes naudojame slapukus, su tikslu pagerinti Jūsų naršymo kokybę bei tobulinti mūsų paslaugų turinį bei naršymo saugumą. Detalesnė informacija mūsų <1>privatumo politikoje</1>. Be to, būtinieji slapukai įgalina pagrindines mūsų svetainės funkcijas; ji negalėtų tinkamai veikti be šių slapukų, todėl šiems slapukams Jūsų sutikimas nėra būtinas; juos galima išjungti tik pakeitus naršyklės nuostatas.': 'We use cookies only with your consent, with the aim of improving the quality of your browsing and, likewise, the content of our services and your browsing security. Please see more detailed information in our <1>privacy policy</1>. Besides, necessary cookies enable the main functions of our website; it would not be able to function properly without these cookies, so your consent is not necessary for these cookies; they can only be disabled by changing your browser settings.',
        'Koreguoti pasirinkimą': 'Adjust the selection',
        'Sutinku su visais': 'Allow all cookies',
        'Ši svetainė naudoja slapukus': 'Cookies in use',
        'Sutinku su pasirinktais': 'Allow sellected cookies',
        'Detaliau': 'More',
        'Pavadinimas': 'Name',
        'Aprašymas': 'Description',
        'Valdytojas': 'Manager',
        'Trukmė': 'Duration',
        'Palaukime': 'Please wait for',
        'kol gausime atsakymą iš banko': 'until we get a confirmation from the bank',
        'Pridėtį į kalendorių': 'Save the date',
        'Uždaryti': 'Close',
        'Kainos': 'Prices',
        'Vietos tipai': 'Seat types',
        'Vietų žymės': 'Seats type',
        'Vietos': 'Seats',
        'Nuolaidų pasirinkimai kitame žingsnyje': 'Discount options are in the next step',
        'Pirkti papildomas renginio paslaugas': 'Additional services',
        'Sektoriai': 'Sectors',
        'Nuolaidas galėsi pritaikyti krepšelyje': 'Discounts can be applied in cart',
        'Ši nuoroda negalioja.': 'The link is not valid',
        'Bilietus į savo norimą renginį kviečiame įsigyti renginių namuose internete:': 'Purchase your ticket in the home of events:',
        'Įsigyti bilietą': 'Buy ticket',
        'Pardavimas sustabdytas': 'Sale is suspended',
        'Pardavimo operacija nepavyko, krepšelis laukimo stadijoje. Jei norite iš naujo pradėti pardavimą, <1>spauskite čia</1>': 'The sale operation failed, the cart is pending. To start the new sale, <1>click here</1>',
        'Įveskite kodą': 'Enter the unlock code',
        'Taikyti': 'Apply',
        'Pasirink datą': 'Select date',
        'Pasirink laiką': 'Select time',
        'Laikas': 'Time',
        'Atsiskaitymas dovanų kuponu apmokėjimo lange': 'You can pay using a gift voucher in the payment window',
        'Dovanų kuponas sėkmingai pratęstas': 'Gift voucher extension is succesfull',
        'Suformuotos rezervacijos keisti negalite.': 'You cannot change already formed reservation.',
        'BE PAPILDOMŲ PASLAUGŲ': 'NO ADDITIONAL SERVICES',
        'PARDAVIMO KODAS': 'SALE CODE',
        'Įvesk pardavimo kodą': 'enter the sale code',
        'Ačiū, kad prenumeruoji kakava.lt naujienlaiškį. Prisijunk prie savo pašto dėžutės ir patvirtink kad sutinki gauti kakava.lt naujienas.': 'Thank you for subscribing to the kakava.lt newsletter. Log in to your mailbox and confirm that you agree to receive kakava.lt news.',
        'PVM mokėtojo kodas': 'VAT Number',
        'Telefono numeris turi prasidėti 370 ir susidaryti iš 11 skaitmenų.': 'The phone number must start with 370 and consist of 11 digits.',
        'Telefono numeris': 'Phone number',
        'Kontaktinė informacija': 'Contact information',
        'g.': 'street'
    }
};
